import emailjs from 'emailjs-com';
import { useEffect, useRef, useState } from 'react';
import '../../styles/MainStyles/applyForm.scss';
import { Genders } from './Genders';
import classNames from 'classnames';
import { Submitted } from './Submitted';
import { ErrorMsg } from './ErrorMsg';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export const ApplyForm = () => {
  const form = useRef<HTMLFormElement>(null);

  const [inputError, setInputError] = useState('');
  const [inputError2, setInputError2] = useState('');
  const [inputError3, setInputError3] = useState('');
  const [inputError4, setInputError4] = useState('');
  const [inputError5, setInputError5] = useState('');
  const [inputError6, setInputError6] = useState('');
  const [inputError7, setInputError7] = useState('');
  const [inputError8, setInputError8] = useState('');
  const [inputError9, setInputError9] = useState('');

  const [isAccepted, setIsAccepted] = useState(false);
  const [gender, setGender] = useState('');
  const [name, setName] = useState('');
  const [age, setAge] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [twitter, setTwitter] = useState('');
  const [ttexpirience, setTtexpirience] = useState('');
  const [englishExpirience, setEnglishExpirience] = useState('');
  const [modelingDirection, setModelingDirection] = useState('');
  const [otherInfo, setOtherInfo] = useState('');
  const [contactPreference, setContactPreference] = useState('');
  const [phoneNumber, setPhoneNumber] = useState<any>(null);

  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  function isValidEmail(email: string) {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  }

  console.log('is there error?:', isError);
  console.log('phone nr:', phoneNumber);

  function sendEmail(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setInputError('');
    setInputError2('');
    setInputError3('');
    setInputError4('');
    setInputError5('');
    setInputError6('');
    setInputError7('');
    setInputError8('');
    setInputError9('');

    let isValid = true;

    if (!isAccepted) {
      setInputError('Please accept the terms and conditions.');

      isValid = false;
    } else if (gender.length === 0) {
      setInputError2('Please select a gender.');

      isValid = false;
    } else if (!name) {
      setInputError3('Please enter your full name.');

      isValid = false;
    } else if (!age) {
      setInputError4('Please enter your age.');

      isValid = false;
    } else if (!instagram) {
      setInputError5('Please enter your Instagram ID.');

      isValid = false;
    } else if (englishExpirience === '') {
      setInputError6('Please indicate your English skill level.');

      isValid = false;
    } else if (!modelingDirection) {
      setInputError7('Please indicate your preferred modeling direction.');

      isValid = false;
    } else if (!isValidEmail(contactPreference)) {
      setInputError8('Please enter a valid email address.');
      isValid = false;
    } else if (!phoneNumber) {
      setInputError9('Please enter your phone number.');
      isValid = false;
    }

    if (!isValid) {
      setIsError(true);
      return;
    }

    const answer = {
      termsConditions: isAccepted,
      gender,
      from_name: name,
      model_age: age,
      instagram: instagram,
      tiktok: tiktok,
      twitter: twitter,
      Tiktok_experience: ttexpirience,
      English_experience: englishExpirience,
      Modeling_direction: modelingDirection,
      Other_info: otherInfo,
      Contact_preference: contactPreference,
      phoneNumber: phoneNumber,
    };

    emailjs
      .send('service_82kvmmn', 'template_7i9mt51', answer, '9WN8cJ5ufjnRL6THS')
      .then(
        (result: { text: any }) => {
          console.log(result.text);
          setIsVisible(true);
          setIsSubmitted(true);
          setIsError(false);
          setIsAccepted(false);
          setGender('');
          setName('');
          setAge('');
          setInstagram('');
          setTiktok('');
          setTwitter('');
          setTtexpirience('');
          setEnglishExpirience('');
          setModelingDirection('');
          setOtherInfo('');
          setContactPreference('');
          setPhoneNumber('');
        },
        (error: { text: any }) => {
          console.log(error.text);
          setIsError(true);
        }
      );
  }

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isSubmitted && isVisible) {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, 10000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isSubmitted, isVisible]);

  return (
    <div
      className='applyForm'
      id='apply'>
      <h2 className='applyH1'>Apply for Agency</h2>

      <form
        ref={form}
        onSubmit={sendEmail}>
        <div
          className={classNames('chekcmark', {
            'input-error': isError && !isAccepted,
          })}>
          I have read and agree to the Privacy Policy
          <input
            type='checkbox'
            id='agree'
            className='checkbox'
            onClick={() => setIsAccepted(!isAccepted)}
          />
        </div>

        <Genders
          setGender={setGender}
          gender={gender}
          isError={isError}
        />

        <input
          className={classNames('input', { 'input-error': isError && !name })}
          type='text'
          placeholder='Full name and surname'
          name='name'
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <input
          className={classNames('input', { 'input-error': isError && !age })}
          type='text'
          placeholder='Age'
          value={age}
          onChange={(event) => setAge(event.target.value)}
          name='age'
        />
        <input
          className={classNames('input', {
            'input-error': isError && !instagram,
          })}
          type='text'
          placeholder='Instagram ID'
          value={instagram}
          onChange={(event) => setInstagram(event.target.value)}
          name='instagram'
        />
        <input
          className='input'
          type='text'
          placeholder='Tiktok ID'
          value={tiktok}
          onChange={(event) => setTiktok(event.target.value)}
        />
        <input
          className='input'
          type='text'
          placeholder='Twitter ID'
          value={twitter}
          onChange={(event) => setTwitter(event.target.value)}
        />

        <div
          className={classNames('questions', {
            'input-error': isError && !ttexpirience,
          })}>
          <div className='qusteionH1'>
            <div className='test'>Experience with TikToks?</div>
          </div>

          <div className='answ1'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive: ttexpirience === 'No, I dont want to make TikToks.',
              })}
              onClick={() =>
                setTtexpirience('No, I dont want to make TikToks.')
              }></button>
            No, I don't want to make TikToks.
          </div>

          <div className='answ'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive:
                  ttexpirience === 'Been thinking about making TikToks',
              })}
              onClick={() =>
                setTtexpirience('Been thinking about making TikToks')
              }></button>
            Been thinking about making TikToks
          </div>

          <div className='answ'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive: ttexpirience === 'I have made TikToks in the past.',
              })}
              onClick={() =>
                setTtexpirience('I have made TikToks in the past.')
              }></button>
            I have made TikToks in the past.
          </div>

          <div className='answ'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive: ttexpirience === 'I am already making TikToks',
              })}
              onClick={() =>
                setTtexpirience('I am already making TikToks')
              }></button>
            I am already making TikToks
          </div>
        </div>

        <div
          className={classNames('questions', {
            'input-error': isError && !englishExpirience,
          })}>
          <div className='qusteionH1'>
            <div className='test'>English skill level.</div>
          </div>

          <div className='answ1'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive: englishExpirience === 'Beginner',
              })}
              onClick={() => setEnglishExpirience('Beginner')}></button>
            Beginner
          </div>
          <div className='answ'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive: englishExpirience === 'Intermediate',
              })}
              onClick={() => setEnglishExpirience('Intermediate')}></button>
            Intermediate
          </div>
          <div className='answ'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive:
                  englishExpirience === 'Professional working proficiency',
              })}
              onClick={() =>
                setEnglishExpirience('Professional working proficiency')
              }></button>
            Professional working proficiency
          </div>
          <div className='answ'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive: englishExpirience === 'Native Language',
              })}
              onClick={() => setEnglishExpirience('Native Language')}></button>
            Native Language
          </div>
        </div>

        <div
          className={classNames('question1', {
            'input-error': isError && !modelingDirection,
          })}>
          <div className='qusteionH1'>
            <div className='test'>Preferred Modeling direction</div>
          </div>

          <div className='answ1'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive:
                  modelingDirection ===
                  'Faceless - I dont want to show my face publicly',
              })}
              onClick={() =>
                setModelingDirection(
                  'Faceless - I dont want to show my face publicly'
                )
              }></button>
            Faceless - I don't want to show my face publicly
          </div>
          <div className='answ'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive:
                  modelingDirection ===
                  'Semi-faceless - I dont want to show my face publicly, but I will show my face in direct messages',
              })}
              onClick={() =>
                setModelingDirection(
                  'Semi-faceless - I dont want to show my face publicly, but I will show my face in direct messages'
                )
              }></button>
            Semi-faceless - I don't want to show my face publicly, but I will
            show my face in direct messages
          </div>
          <div className='answ'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive:
                  modelingDirection ===
                  'Standard (Recommended) - Showing face publicly',
              })}
              onClick={() =>
                setModelingDirection(
                  'Standard (Recommended) - Showing face publicly'
                )
              }></button>
            Standard (Recommended) - Showing face publicly
          </div>
          <div className='answ'>
            <button
              type='button'
              className={classNames('btnAnsw', {
                btnActive: modelingDirection === 'Not sure.',
              })}
              onClick={() => setModelingDirection('Not sure.')}></button>
            Not sure.
          </div>
        </div>

        <input
          className='input'
          type='text'
          placeholder='Other information regarding application '
          value={otherInfo}
          onChange={(event) => setOtherInfo(event.target.value)}
        />

        <input
          className={classNames('howInput', {
            'input-error': isError && !contactPreference,
          })}
          type='text'
          placeholder='Your email address'
          value={contactPreference}
          onChange={(event) => setContactPreference(event.target.value)}
        />
        <PhoneInput
          placeholder='Enter phone number'
          value={phoneNumber || undefined}
          onChange={setPhoneNumber}
          className={classNames('input2', {
            'input-error': isError && !phoneNumber,
          })}
        />

        {isVisible && <Submitted />}

        {isError && (
          <ErrorMsg
            inputError={inputError}
            inputError2={inputError2}
            inputError3={inputError3}
            inputError4={inputError4}
            inputError5={inputError5}
            inputError6={inputError6}
            inputError7={inputError7}
            inputError8={inputError8}
            inputError9={inputError9}
          />
        )}

        <button
          className='submit'
          tabIndex={0}
          type='submit'>
          Submit
        </button>
      </form>
    </div>
  );
};
