import { useState } from 'react';
import '../styles/photoRoll.scss';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';

export const PhotoRoll = () => {
  const [btn, setBtn] = useState('btn1');

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (btn === 'btn1') setBtn('btn2');
      else if (btn === 'btn2') setBtn('btn3');
    },
    onSwipedRight: () => {
      if (btn === 'btn3') setBtn('btn2');
      else if (btn === 'btn2') setBtn('btn1');
    },
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className='photoRoll'>
      {btn === 'btn1' && (
        <div className='photo1'>
          <h3 className='photoH'>Marketing</h3>
          <div className='photoText1'>
            Our team at Duval is a carefully assembled group of professionals,
            each with a distinct role, yet all sharing a common goal: your
            success.
          </div>
        </div>
      )}

      {btn === 'btn2' && (
        <div className='photo2'>
          <h3 className='photoH'>Client Manager </h3>
          <div className='photoText2'>
            Client Manager serves as your main point of contact with our team.
            <br />
            <br />
            The Client Manager ensures smooth communication, aligns the team
            with your goals, and bridges the gap between your vision and its
            execution.
          </div>
        </div>
      )}

      {btn === 'btn3' && (
        <div className='photo3'>
          <h3 className='photoH'>Client Manager </h3>
          <div className='photoText1'>
            consists of 3 to 5 experts assigned to each account. This team
            ensures that your account stays on track with the set goals,
            generates effective marketing strategies, and executes them
            meticulously.
          </div>
        </div>
      )}

      <div className='btnBlock'>
        <button
          className={classNames('teamBtn', { activebtn: btn === 'btn1' })}
          onClick={() => setBtn('btn1')}></button>
        <button
          className={classNames('teamBtn2', { activebtn: btn === 'btn2' })}
          onClick={() => setBtn('btn2')}></button>
        <button
          className={classNames('teamBtn3 ', { activebtn: btn === 'btn3' })}
          onClick={() => setBtn('btn3')}></button>
      </div>
    </div>
  );
};
