import '../../styles/MainStyles/readMore.scss';

type Blog = {
  title1?: string;
  text1?: string;
  'text1.1'?: string;
  title2?: string;
  text2?: string;
  'text2.1'?: string;
  title3?: string;
  text3?: string;
  'text3.2'?: string;
  title4?: string;
  text4?: string;
  'text4.1'?: string;
  'text4.2'?: string;
};

type ReadMoreProps = {
  blog: Blog;
  expandedItems: Record<number, boolean>;
};

export const ReadMore = (
  { blog }: ReadMoreProps,
  expandedItems: Record<number, boolean>
) => {
  return (
    <div className='readMore expd'>
      <div className='title1'>{blog.title1}</div>
      <div className='text1'>{blog.text1}</div>
      <div className='text1-1'>{blog['text1.1']}</div>

      <div className='title2'>{blog.title2}</div>
      <div className='text1'>{blog.text2}</div>
      <div className='text1-1'>{blog['text2.1']}</div>

      <div className='title3'>{blog.title3}</div>
      <div className='text1'>{blog.text3}</div>
      <div className='text1-1'>{blog['text3.1' as keyof typeof blog]}</div>
      <div className='text1-2'>{blog['text3.2']}</div>

      <div className='title4'>{blog.title4}</div>
      <div className='text1'>{blog.text4}</div>
      <div className='text1-1'>{blog['text4.1']}</div>
      <div className='text1-2'>{blog['text4.2']}</div>
    </div>
  );
};
