import '../../styles/MainStyles/aboutUs.scss';
import { PhotoRoll } from '../PhotoRoll';
import { PhotoRoll2 } from '../PhotoRoll2';
import { VideoRoll } from '../VideoRoll';

export const AboutUs = () => {
  return (
    <div
      className='aboutUs'
      id='about us'>
      <h2 className='aboutH1'>About us</h2>
      <div className='aboutText'>
        Behind every successful OnlyFans model is a team that stands strong in
        the face of fluctuating trends and market challenges.
      </div>
      <div className='teamBg'></div>
      <h3 className='aboutH2'>We are that team. . . </h3>
      <div className='aboutText2'>
        Founded in the heart of the digital revolution, we are a collective of
        seasoned strategists, dedicated account
        <br /> managers,
        <br /> and passionate creators.
      </div>
      <div className='aboutText2'>
        We are bound by one mission <br />- to propel your OnlyFans journey to
        unparalleled <br />
        heights.
      </div>
      <div className='aboutText2'>
        We operate in the realm of authenticity, delivering consistent results
        while championing trust and open communication.
      </div>
      <h3 className='aboutH2'>With us,</h3>
      <div className='aboutText2'>
        you're not just another model on a platform; you're on your way to
        standout success and a new level of achievement on OnlyFans. Welcome to
        a partnership built on mutual success
      </div>
      <h3 className='aboutH3'>Don't just take our word for it.</h3>
      <div className='aboutText2'>
        Hear the success stories directly from our models who have transformed
        their OnlyFans journey with us.
      </div>
      <VideoRoll />

      <h3 className='aboutH4'>Our beliefs</h3>

      <div className='aboutText2'>
        At Duval, we believe that behind every successful OnlyFans creator is a
        strong, dedicated team, a strategy grounded in data, and an unwavering
        commitment to transparent communication. .
      </div>

      <div className='aboutText2'>
        Our agency is built on these pillars, ensuring our creators not only
        reach their potential but exceed it.
      </div>

      <PhotoRoll />

      <h3 className='aboutH4'>Our operations</h3>
      <div className='aboutText2'>
        Our operations at Duval revolve around two main areas: marketing and
        creator management.
      </div>

      <PhotoRoll2 />

      <h3 className='aboutH4'>Our technologies</h3>
      <div className='aboutText2'>
        In the fast-paced world of OnlyFans, real-time metrics are invaluable.
      </div>
      <div className='aboutText2'>
        Our operations team uses advanced software that ties directly into
        OnlyFans, providing us with real-time fan data. This software identifies
        dozens of data points, enabling our team to make day-to-day adjustments
        to our strategy.
      </div>
      <div className='aboutText2'>
        With this powerful tool, we can predict trends, identify opportunities,
        and make informed decisions that keep your account thriving.
      </div>

      <h3 className='aboutH4'>The Duval Difference</h3>
      <div className='aboutText2'>
        At Duval, our approach is built around you. We're not just another
        agency; we're your dedicated partners in this journey. We believe in
        transparency, daily communication, and strategies grounded in real-time
        data. We're here to not only manage your OnlyFans account but to pave
        the way to your success in the platform.
      </div>

      <h2 className='welcome'>
        “Welcome to Duval, where your dreams become our mission.”
      </h2>
    </div>
  );
};
