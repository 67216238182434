import '../styles/navigation.scss';
import { Link } from 'react-scroll';
import classNames from 'classnames';
import ReactGA from 'react-ga';

type Props = {
  setPageManager: (pageManager: boolean) => void;
  isOpened: boolean;
  setIsOpened: (isOpened: boolean) => void;
};

ReactGA.initialize('G-PVFG9DNHVF');

export const Navigation: React.FC<Props> = ({
  setPageManager,
  isOpened,
  setIsOpened,
}) => {
  const setMainPage = () => {
    setPageManager(false);
    setIsOpened(false);
  };

  const sectionToUrlMap: { [key: string]: string } = {
    model: '/models',
    'about us': '/aboutus',
    'news&blog': '/blogs',
    socials: '/socials',
    apply: '/applyform',
    faq: '/faq',
    policy: '/privacy',
  };

  const trackPageView = (section: keyof typeof sectionToUrlMap) => {
    const url = sectionToUrlMap[section];

    // record a page view
    ReactGA.pageview(url);
  };

  return (
    <div className={classNames('navigation', { open: isOpened })}>
      <button className='btn'>
        <Link
          onClick={setMainPage}
          to='model'
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}
          onSetActive={() => trackPageView('model')}>
          For Models
        </Link>
      </button>

      <button className='navbtn'>
        <Link
          onClick={setMainPage}
          to='about us'
          spy={true}
          smooth={true}
          offset={-20}
          duration={500}
          onSetActive={() => trackPageView('about us')}>
          About us
        </Link>
      </button>

      <button className='navbtn'>
        <Link
          onClick={setMainPage}
          to='news&blog'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onSetActive={() => trackPageView('News&blogs')}>
          News & Blog
        </Link>
      </button>

      <button className='navbtn'>
        <Link
          onClick={setMainPage}
          to='socials'
          spy={true}
          smooth={true}
          offset={-40}
          duration={500}
          onSetActive={() => trackPageView('socials')}>
          Socials
        </Link>
      </button>
      <button className='navbtn1'>
        <Link
          onClick={setMainPage}
          to='apply'
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          onSetActive={() => trackPageView('apply')}>
          Apply for agency
        </Link>
      </button>
      <button className='navbtn'>
        <Link
          onClick={setMainPage}
          to='faq'
          spy={true}
          smooth={true}
          offset={-80}
          duration={500}
          onSetActive={() => trackPageView('faq')}>
          FAQ
        </Link>
      </button>
      <button className='navbtn'>
        <Link
          onClick={setMainPage}
          to='policy'
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          onSetActive={() => trackPageView('policy')}>
          Privacy Policy
        </Link>
      </button>
      <button
        onClick={() => {
          setPageManager(true);
          setIsOpened(false);
        }}
        className='btnManager'>
        For Managers
      </button>

      <div className='bottomNav'>
        <div className='rectangle'></div>
      </div>
    </div>
  );
};
