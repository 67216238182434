import React, { useState } from 'react';
import './App.css';
import { Header } from './Components/Header';
import { Manager } from './Components/Manager';
import { MainPage } from './Components/MainPage';

export const App = () => {
  const [pageManager, setPageManager] = useState(false);
  return (
    <div className='App'>
      <Header setPageManager={setPageManager} />

      {pageManager ? <Manager /> : <MainPage />}
    </div>
  );
};
