import '../styles/mainPage.scss';
import { AboutUs } from './MainComponents/AboutUs';
import { ApplyForm } from './MainComponents/ApplyForm';
import { FAQ } from './MainComponents/FAQ';
import { ForModels } from './MainComponents/ForModels';
import { NewsBlogs } from './MainComponents/NewsBlogs';
import { Privacy } from './MainComponents/Privacy';
import { Social } from './MainComponents/Social';
import { Helmet } from 'react-helmet';

export const MainPage = () => {
  return (
    <div className='mainPage'>
      <Helmet>
        <title>
          Duval Agency: Premier OnlyFans Management Company & Agency
        </title>
        <meta
          name='description'
          content='Duval Agency, a top-tier OnlyFans management agency and company, empowers models with strategic tools to dominate the platform. Join us for unparalleled success.'
        />
      </Helmet>
      <ForModels />
      <AboutUs />
      <NewsBlogs />
      <Social />
      <ApplyForm />
      <FAQ />
      <Privacy />
    </div>
  );
};
