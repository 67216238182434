import '../../styles/MainStyles/privacy.scss';
import { Link } from 'react-scroll';

export const Privacy = () => {
  return (
    <>
      <div
        className='privacy'
        id='policy'>
        <div className='leftPt'>
          <div className='logoYellow'></div>
          <div className='location'>
            Corporate Headquarters9171 Wilshire BoulevardSTE 500Beverly Hills,
            CA 90210
          </div>
        </div>

        <div className='middle1'>
          <button className='privacyH'>
            <Link
              to='model'
              spy={true}
              smooth={true}
              offset={-60}
              duration={500}>
              For Models
            </Link>
          </button>
          <button className='navig'>
            <Link
              to='model'
              spy={true}
              smooth={true}
              offset={-60}
              duration={500}>
              Home
            </Link>
          </button>
          <button className='navig1'>
            <Link
              to='about us'
              spy={true}
              smooth={true}
              offset={-20}
              duration={500}>
              About us
            </Link>
          </button>
          <button className='navig'>
            <Link
              to='news&blog'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}>
              News
            </Link>
          </button>
          <button className='navig'>
            <Link
              to='apply'
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}>
              Apply for agency
            </Link>
          </button>
        </div>

        <div className='middle1'>
          <button className='privacyH'>For Managers</button>
          <button className='navig'> Courses</button>
        </div>

        <div className='middle1'>
          <button className='privacyH'>
            <Link
              to='socials'
              spy={true}
              smooth={true}
              offset={-40}
              duration={500}>
              Socials
            </Link>
          </button>
          <button
            className='navigSvg1'
            onClick={() =>
              window.open('https://www.instagram.com/duvalagency/', '_blank')
            }
          />
        </div>
      </div>

      <div className='footer'>
        <div className='fooTxt'>
          © 2023 Duval Agency LCC / ALL RIGHTS RESERVED
        </div>
      </div>
    </>
  );
};
