import '../styles/manager.scss';

export const Manager = () => {
  return (
    <div
      className='courseApp'
      id='for managers'>
      <div className='headerbox'>
        Onlyfans <br />
        Course for
        <br /> managers
      </div>
      <button
        className='coursebtn'
        onClick={() =>
          window.open('https://onlyfanstiktokdomination.mysellix.io/', '_blank')
        }>
        Our courses
      </button>

      <div className='infobox'>
        <div className='nobodyTell'>Nobody will tell you this?</div>
        <div className='info1'>
          It started as a kooky soap company with a counterculture ethos. Today,
          the family-run business is an unlikely juggernaut and one of the most
          outspoken companies in America. Here’s how Dr. Bronner’s is rewriting
          the playbook for corporate success by doubling down on its vibey
          values.
        </div>
        <div className='info2'>
          It started as a kooky soap company with a counterculture ethos. Today,
          the family-run business is an unlikely juggern
        </div>

        <div className='h2'>We offer</div>
        <div className='info3'>
          It started as a kooky soap company with a counterculture ethos. Today,
          the family-run business is an unlikely juggern
        </div>
      </div>
    </div>
  );
};
