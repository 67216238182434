import blogData from '../../Data/loadMoreBlogs.json';

import { useState } from 'react';
import classNames from 'classnames';
import { ReadMore } from './ReadMore';

export const LoadMore = () => {
  const [isbtnnClicked, setIsbtnnClicked] = useState(false);
  const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>(
    {}
  );

  const toggleItem = (index: number) => {
    setExpandedItems({
      ...expandedItems,
      [index]: !expandedItems[index],
    });
  };

  return (
    <div className='loadMore'>
      {blogData.blogs.map((blog: any, index: number) => (
        <div
          className='newsContainer'
          key={index}>
          <div className={`blogImg${index + 4}`}></div>
          <h3 className='title'>{blog.title}</h3>
          <div className='content'>{blog.text}</div>
          {expandedItems[index] && (
            <ReadMore
              blog={blog}
              expandedItems={expandedItems}
            />
          )}

          <button
            className='moreBtn'
            onClick={() => {
              setIsbtnnClicked(!isbtnnClicked);
              toggleItem(index);
            }}>
            read more
            <div
              onClick={() => setIsbtnnClicked(!isbtnnClicked)}
              className={classNames('goldarrow', {
                rotates: expandedItems[index],
                rotatesBack: !expandedItems[index],
              })}></div>
          </button>
        </div>
      ))}
    </div>
  );
};
