import { useState } from 'react';
import '../styles/headers.scss';
import classNames from 'classnames';
import { Navigation } from './Navigation';
import { Link } from 'react-scroll';

type Props = {
  setPageManager: (pageManager: boolean) => void;
};

export const Header: React.FC<Props> = ({ setPageManager }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className='container'>
      <div className='header'>
        <button
          className={classNames('menu', { active: isOpened })}
          onClick={() => setIsOpened(!isOpened)}>
          <div className='line1'></div>
          <div className='line2'></div>
          <div className='line3'></div>
        </button>

        <button className='logobox'>
          <Link
            to='model'
            spy={true}
            smooth={true}
            offset={-60}
            duration={500}>
            <div className='logo'></div>
          </Link>
        </button>
      </div>
      <div className='bottom'>
        <div className='rectangle'></div>
      </div>
      {isOpened && (
        <Navigation
          setPageManager={setPageManager}
          isOpened={isOpened}
          setIsOpened={setIsOpened}
        />
      )}
    </div>
  );
};
