import { useRef, useState } from 'react';

// import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';

import '../styles/videoRoll.scss';
const viedo1 = require('../videos/video1.mp4');
// const viedo2 = require('../videos/video2.mp4');
// const viedo3 = require('../videos/video3.mp4');
export const VideoRoll = () => {
  // const [isScrolled, setIsScrolled] = useState(false);
  const [btn, setBtn] = useState('btn1');

  const videoRef = useRef<HTMLVideoElement>(null);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (btn === 'btn1') setBtn('btn2');
      else if (btn === 'btn2') setBtn('btn3');
    },
    onSwipedRight: () => {
      if (btn === 'btn3') setBtn('btn2');
      else if (btn === 'btn2') setBtn('btn1');
    },
    // preventDefaultTouchmoveEvent: true,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  // useEffect(() => {
  //   const checkScroll = () => {
  //     if (!videoRef.current) return;

  //     const rect = videoRef.current.getBoundingClientRect();
  //     const elemTop = rect.top;
  //     const elemBottom = rect.bottom;
  //     const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  //     const percentScrolled = Math.abs(rect.top) / rect.height;

  //     if (isVisible && percentScrolled > 0.4 && !isScrolled) {
  //       setIsScrolled(true);
  //       videoRef.current.play();
  //     }
  //   };

  //   window.addEventListener('scroll', checkScroll);

  //   return () => window.removeEventListener('scroll', checkScroll);
  // }, [isScrolled]);

  return (
    <div
      {...handlers}
      className='videoRoll'>
      {btn === 'btn1' && (
        <div className='video1'>
          <video
            ref={videoRef}
            width='360px'
            height='646px'
            preload='auto'
            controls>
            <source
              src={viedo1}
              type='video/mp4'
            />
          </video>
        </div>
      )}

      {/* {btn === 'btn2' && (
        <div className='video2'>
          <video
            ref={videoRef}
            width='360px'
            height='646px'
            controls>
            <source
              src={viedo2}
              type='video/mp4'
            />
          </video>
        </div>
      )}

      {btn === 'btn3' && (
        <div className='video3'>
          <video
            ref={videoRef}
            width='360px'
            height='646px'
            controls>
            <source
              src={viedo3}
              type='video/mp4'
            />
          </video>
        </div>
      )} */}

      {/* <div className='videoBtnBlock'>
        <button
          className={classNames('teamBtn', { activebtn: btn === 'btn1' })}
          onClick={() => setBtn('btn1')}></button>
        <button
          className={classNames('teamBtn2', { activebtn: btn === 'btn2' })}
          onClick={() => setBtn('btn2')}></button>
        <button
          className={classNames('teamBtn3 ', { activebtn: btn === 'btn3' })}
          onClick={() => setBtn('btn3')}></button>
      </div> */}
    </div>
  );
};
