import '../../styles/MainStyles/submitted.scss';

export const Submitted = () => {
  return (
    <div className='submitted'>
      <div className='succes'>Submitted successfully</div>
      <div className='willReview'>
        We will review your form and get back to you soon
      </div>
      <div className='forInfo'>
        For information on how your data is saved check out our Privacy Policy
      </div>
    </div>
  );
};
