import { useState } from 'react';
import '../styles/photoRoll.scss';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';

export const PhotoRoll2 = () => {
  const [btn, setBtn] = useState('btn1');

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (btn === 'btn1') setBtn('btn2');
      else if (btn === 'btn2') setBtn('btn3');
    },
    onSwipedRight: () => {
      if (btn === 'btn3') setBtn('btn2');
      else if (btn === 'btn2') setBtn('btn1');
    },
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className='photoRoll'>
      {btn === 'btn1' && (
        <div className='photo4'>
          <div className='photoH'>Marketing</div>
          <div className='photoText1'>
            In terms of marketing, we focus heavily on the power of social media
            platforms, particularly TikTok. Our strategies are designed to
            position you in front of your target audience and convert viewers
            into loyal subscribers.
          </div>
        </div>
      )}

      {btn === 'btn2' && (
        <div className='photo5'>
          <div className='photoH'>Management </div>
          <div className='photoText2'>
            For creator management, we provide weekly instructions on how to
            perform certain tasks that align with our marketing strategies.
            <br />
            <br />
            This empowers you to take active part in your journey, providing the
            right content at the right time for maximum impact.
          </div>
        </div>
      )}

      {btn === 'btn3' && (
        <div className='photo6'>
          <div className='photoH'>Additionally</div>
          <div className='photoText1'>
            When custom content is offered, our account team takes charge of the
            process, negotiating a high price and communicating the custom
            request to you.
            <br />
            <br />
            This ensures that you are compensated fairly for your time and
            effort while meeting the unique needs of your fans.
          </div>
        </div>
      )}

      <div className='btnBlock'>
        <button
          className={classNames('teamBtn', { activebtn: btn === 'btn1' })}
          onClick={() => setBtn('btn1')}></button>
        <button
          className={classNames('teamBtn2', { activebtn: btn === 'btn2' })}
          onClick={() => setBtn('btn2')}></button>
        <button
          className={classNames('teamBtn3 ', { activebtn: btn === 'btn3' })}
          onClick={() => setBtn('btn3')}></button>
      </div>
    </div>
  );
};
