import { useEffect, useState } from 'react';
import '../../styles/MainStyles/social.scss';
import axios from 'axios';

const ACCESS_TOKEN =
  'IGQWRNMmt3Yy1Iekk2M1BwMUhqZAlhyRzFGbU9ZAQ29nV2hwdUF1RE5WRWdqNmEwU3J5X0VQOWZASWXVPM1EwT3d5bXdJWW1Rakx3YTJvNU9nQlh5eUJodU4yakh4aDcteXZAZARW1rX19ZAVG1qaHJ2UW05SnJYX3REWlEZD';

interface Photo {
  id: string;
  media_type: string;
  media_url: string;
  thumbnail_url?: string;
  permalink: string;
  caption: string;
}

export const Social = () => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  useEffect(() => {
    const fetchPhotos = async () => {
      const userIdRes = await axios.get(
        `https://graph.instagram.com/me?fields=id&access_token=${ACCESS_TOKEN}`
      );
      const userId = userIdRes.data.id;

      const res = await axios.get(
        `https://graph.instagram.com/${userId}/media?fields=id,media_type,media_url,thumbnail_url,permalink,caption&access_token=${ACCESS_TOKEN}`
      );

      setPhotos(res.data.data);
    };

    fetchPhotos();
  }, []);

  useEffect(() => {
    if (photos.length > 0) {
      const interval = setInterval(() => {
        setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
      }, 3500); // Change every 3 seconds

      // Clean up interval on unmount
      return () => clearInterval(interval);
    }
  }, [photos]);

  return (
    <>
      <div
        className='social'
        id='socials'>
        <h2 className='socialH1'>Social</h2>
        <div className='socialTxt'>
          Social media is the pulse of modern culture, and when it comes to
          OnlyFans, staying informed can be your ticket to success.
        </div>
        <div className='socialTxt'>
          By following Duval on Instagram, you'll gain access to the latest
          OnlyFans news, tips, and strategies straight from the industry
          experts. Don't just keep up, stay ahead with Duval.
        </div>

        <div
          className='igPhotos'
          onClick={() =>
            window.open('https://www.instagram.com/duvalagency/', '_blank')
          }>
          {photos
            .slice(currentPhotoIndex, currentPhotoIndex + 1)
            .map((photo, index) => (
              <img
                key={photo.id}
                src={photo.media_url}
                alt={photo.caption}
                className='igPhoto'
              />
            ))}
        </div>
        <button
          className='socialsBtn'
          type='button'
          onClick={() =>
            window.open('https://www.instagram.com/duvalagency/', '_blank')
          }>
          FOLLOW US
        </button>
      </div>
    </>
  );
};
