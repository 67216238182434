import '../../styles/MainStyles/forModels.scss';

import { scroller } from 'react-scroll';

export const ForModels = () => {
  return (
    <div
      className='forModels'
      id='model'>
      <div className='model1'></div>

      <h1 className='modelH1'>
        Onlyfans <br /> Modeling Agency
      </h1>

      <button
        className='modelbtn'
        onClick={() =>
          scroller.scrollTo('apply', {
            spy: true,
            smooth: true,
            offset: -75,
            duration: 800,
          })
        }>
        APPLY NOW
      </button>

      <div className='modelH2'>
        Nobody will tell you <br /> this?
      </div>
      <div className='modelText'>
        success isn't just about stunning visuals, it's about intelligent
        strategy, relentless consistency, and unwavering trust.
      </div>
      <div className='modelText'>
        At our agency, we redefine the game, empowering models with the tools to
        dominate the platform and reach their highest potential.
      </div>
      <div className='modelText'>
        We operate in the realm of the top 0.1%, providing a service that
        assures real, consistent results.
      </div>
      <div className='modelText'>
        Welcome to a realm where elegance meets professionalism, where your
        achievements become our shared success. Let's redefine your journey in
        the OnlyFans world together.
      </div>
    </div>
  );
};
