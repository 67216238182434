import classNames from 'classnames';
import '../../styles/MainStyles/genders.scss';

type Props = {
  setGender: (gender: string) => void;
  gender: string;
  isError: boolean;
};

export const Genders: React.FC<Props> = ({ setGender, gender, isError }) => {
  return (
    <div
      className={classNames('genders', { 'input-error': isError && !gender })}>
      <div className='field'>
        <button
          className={classNames('btnGenders', {
            btnactive: gender === 'Female',
          })}
          type='button'
          onClick={() => {
            setGender('Female');
          }}></button>
        <div className='gender'>Female</div>
      </div>

      <div className='field'>
        <button
          className={classNames('btnGenders', { btnactive: gender === 'Male' })}
          type='button'
          onClick={() => {
            setGender('Male');
          }}></button>
        <div className='gender'>Male</div>
      </div>

      <div className='field'>
        <button
          className={classNames('btnGenders', {
            btnactive: gender === 'Other',
          })}
          type='button'
          onClick={() => {
            setGender('Other');
          }}></button>
        <div className='gender'>Other</div>
      </div>
    </div>
  );
};
