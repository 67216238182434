import { useState } from 'react';
import '../../styles/MainStyles/faq.scss';
import classNames from 'classnames';

import faqData from '../../Data/faq.json';

export const FAQ = () => {
  const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>(
    {}
  );

  const toggleItem = (index: number) => {
    setExpandedItems({
      ...expandedItems,
      [index]: !expandedItems[index],
    });
  };

  return (
    <div
      className='faq'
      id='faq'>
      <h2 className='faqH1'>FREQUENTLY ASKED QUESTIONS</h2>

      {faqData.faqs.map((faq, index) => (
        <div key={index}>
          <button
            className='faqBtn'
            onClick={() => toggleItem(index)}>
            <div className='text'>{faq.question}</div>
            <div
              className={classNames('arrow', {
                rotates: expandedItems[index],
                rotatesBack: !expandedItems[index],
              })}
            />
          </button>

          {expandedItems[index] && (
            <div
              className={classNames('anstext', {
                expanded: expandedItems[index],
              })}>
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
