import '../../styles/MainStyles/errorMsg.scss';

type Props = {
  inputError: string;
  inputError2: string;
  inputError3: string;
  inputError4: string;
  inputError5: string;
  inputError6: string;
  inputError7: string;
  inputError8: string;
  inputError9: string;
};

export const ErrorMsg: React.FC<Props> = ({
  inputError,
  inputError2,
  inputError3,
  inputError4,
  inputError5,
  inputError6,
  inputError7,
  inputError8,
  inputError9,
}) => {
  return (
    <div className='error'>
      <div className='error1'>{inputError}</div>
      <div className='error1'>{inputError2}</div>
      <div className='error1'>{inputError3}</div>
      <div className='error1'>{inputError4}</div>
      <div className='error1'>{inputError5}</div>
      <div className='error1'>{inputError6}</div>
      <div className='error1'>{inputError7}</div>
      <div className='error1'>{inputError8}</div>
      <div className='error1'>{inputError9}</div>
    </div>
  );
};
